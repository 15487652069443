<template>
    <section class="tables">
        <div class="page-header">
            <h3 class="page-title">
                <button type="button" class="btn btn-outline-success btn-sm mr-2" @click="goBack()">
                    <i class="mdi mdi-keyboard-backspace"></i></button>
                Order #{{ order?.original_transaction_id }}
            </h3>

            <!-- <div class="btn-group" role="group" aria-label="Basic example">
                <button @click="showAddModal()" type="button" class="btn btn-success believe-btn">
                    <i class="fa fa-plus"></i> Add Ebook</button>
            </div> -->
        </div>
        <div class="row">
            <div class="col-md-6 grid-margin stretch-card">
                <div class="card">
                    <div class="card-header">
                        Order details
                    </div>
                    <div class="card-body">
                        <h2 v-if="order == null || order.length == 0">
                            No Details found!
                        </h2>
                        <div v-else class="current-order">
                            <p><b>Name : </b>{{ order?.customer?.name }}</p>
                            <p><b>Email : </b><a href="#">{{ order?.customer?.email }}</a></p>
                            <p v-if="order?.price_in_purchased_currency > 0"><b>Status : </b>Paid</p>
                            <!-- {{ order?.status }} -->
                            <p><b>Transaction id : </b>{{ order?.original_transaction_id }}</p>
                            <p><b>Customer id : </b>{{ order?.original_app_user_id }}</p>
                            <p><b>Order Date : </b>{{ getFormattedTime(order?.created_at) }}</p>
                            <!-- <p><b>Price : </b>{{ order?.amount }}</p> -->
                            <p><b>Paid : </b>{{ order?.price_in_purchased_currency }}</p>
                            <!-- <p><b>Tax : </b>USD 0.00</p> -->
                            <!-- <p><b>Total : </b>{{ order?.amount }}</p> -->

                            <!-- <table class="table" style="width:30%">
                                <tr>
                                    <td><b>Order id</b></td>
                                    <td>{{ order?.platform_specific_id }}</td>
                                </tr>
                                <tr>
                                    <td><b>Date</b></td>
                                    <td>{{ order?.created_at | moment('ll') }}</td>
                                </tr>
                                <tr>
                                    <td><b>Price</b></td>
                                    <td>{{ order?.amount }}</td>
                                </tr>
                                <tr>
                                    <td><b>Tax</b></td>
                                    <td>USD 0.00</td>
                                </tr>
                                <tr>
                                    <td><b>Total</b></td>
                                    <td>{{  order?.amount }}</td>
                                </tr>
                            </table> -->
                            <!-- <br />
                            <div>
                                <a href="javascript:;">Refund</a> | <a href="javascript:;">Cancel Subscription</a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-md-6 grid-margin stretch-card">
                <div class="card">
                    <div class="card-header">
                        Package in this order
                    </div>
                    <div class="card-body">
                        <h2 v-if="order == null || order.length == 0">
                            No Package found!
                        </h2>
                        <div v-else class="current-order-product">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Package</th>
                                        <th>Platform</th>
                                        <th>Qty</th>
                                        <!-- <th>Price</th> -->
                                        <th>Paid</th>
                                        <!-- <th>Tax</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ order.plan_id == '1' ? 'Monthly' : 'Yearly' }}</td>
                                        <td>{{ order.platform }}</td>
                                        <td>1</td>
                                        <!-- <td>{{ order?.amount }}</td> -->
                                        <!-- <td v-if="order?.paid">{{ order?.paid }}</td> -->
                                        <td>{{ order?.price_in_purchased_currency }}</td>
                                        <!-- <td>USD 0.00</td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-header">
                        Events
                    </div>
                    <div class="card-body">
                        <div class="current-order-product">
                            <h2 v-if="history == null || history.length == 0">
                                No Event found!
                            </h2>
                            <div v-else>
                            <h4>Total paid of this package: &nbsp;&nbsp; {{ total_of_history }}</h4>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <!-- <th>#</th> -->
                                        <th>Date</th>
                                        <th>Package Name</th>
                                        <th>Platform</th>
                                        <!-- <th>Status</th> -->
                                        <!-- <th>Payment Status</th> -->
                                        <!-- <th>Price</th> -->
                                        <th>Currency</th>
                                        <th>Paid</th>
                                        <th>Type</th>
                                        <th>Period Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in history" :key="item.id">
                                        <!-- <td scope="row" class="sorting_1">{{ index + 1 }}</td> -->
                                        <td>{{ getFormattedTime(item.created_at) }}</td>
                                        <td>{{ item.plan_id == '1' ? 'Monthly' : 'Yearly' }}</td>
                                        <td>{{ item.platform }}</td>
                                        <!-- <td>{{ item.status }}</td> -->
                                        <!-- <td>paid</td> -->
                                        <!-- <td>
                                            {{ (item.status == 'active' || item.status == 'renew' || item.status == 'changed') ? item.amount : '' }}
                                        </td> -->
                                        <td>{{ item.currency }}</td>
                                        <td>{{ item.price_in_purchased_currency }}</td>
                                        <!-- <td>{{ (item.status == 'active' || item.status == 'renew' || item.status == 'changed') ? item.paid : '' }}</td> -->
                                        <td>{{ item.type  }}</td>
                                        <td>{{ item.period_type  }}</td>
                                    </tr>
                                    <!-- <tr>
                                        <td>Jan 17, 2023 3:41:01 AM UTC</td>
                                        <td>Payment pending</td>
                                        <td>Order Received</td>
                                    </tr>
                                    <tr>
                                        <td>Jan 17, 2023 3:41:01 AM UTC</td>
                                        <td>Payment pending</td>
                                        <td>Order Received</td>
                                    </tr> -->
                                </tbody>
                                <!-- <tfoot>
                                    <tr class="">
                                        <td v-for="(item, index) in history" :key="item.id">
                                            <b v-if="item.pay">Total paid of this package: &nbsp;&nbsp; {{ item.pay }}</b>
                                        </td>
                                    </tr>
                                </tfoot> -->

                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-header">
                        Other Orders
                    </div>
                    <div class="card-body">
                        <div class="current-order-product">
                            <h2 v-if="latest_orders == null || latest_orders.length == 0">
                                No Records Found!
                            </h2>
                            <div v-else>
                            <!-- <h4>Total paid of this package: &nbsp;&nbsp; {{ total_of_other_order }}</h4> -->
                            <table class="table table-responsive">
                                <thead>
                                    <tr>
                                         <!-- <th>#</th> -->
                                         <th>Date</th>
                                        <th>Package Name</th>
                                        <th>Platform</th>
                                        <!-- <th>Status</th> -->
                                        <!-- <th>Payment Status</th> -->
                                        <!-- <th>Price</th> -->
                                        <th>Currency</th>
                                        <th>Paid</th>
                                        <!-- <th>Type</th>
                                        <th>Period Type</th> -->
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in latest_orders" :key="item.id">
                                        <td>{{ getFormattedTime(item.created_at) }}</td>
                                        <td>{{ item.plan_id == '1' ? 'Monthly' : 'Yearly' }}</td>
                                        <td>{{ item.platform }}</td>
                                        <!-- <td style="width:30%">{{ item.platform_specific_id }}</td> -->
                                        <td>{{ item.currency  }}</td>
                                        <td>{{ item.price_in_purchased_currency  }}</td>
                                        <!-- <td>{{ item.type }}</td>
                                        <td>{{ item.period_type }}</td> -->
                                        <td><router-link class="btn btn-gradient-success"
                                                :to="{ name: 'OrderDetails', params: { id: item.id }}">View
                                                Order</router-link></td>
                                    </tr>
                                    <!-- <tr>
                                        <td>Jan 17, 2023 3:41:01 AM UTC</td>
                                        <td>Payment pending</td>
                                        <td>Order Received</td>
                                        <td>Order Received</td>
                                        <td>Order Received</td>
                                        <td><router-link :to="{ name: 'OrderDetails',params: { id: item.id }}">View Order</router-link></td>
                                    </tr> -->
                                </tbody>
                                <!-- <tfoot>
                                    <tr class="">
                                        <td v-for="(item, index) in latest_orders" :key="item.id">
                                            <b v-if="item.pay">Total paid of this package: &nbsp;&nbsp; {{ item.pay }}</b>
                                        </td>
                                    </tr>
                                </tfoot> -->
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- <h4 class="card-title" style="color:#696969 !important;">Scripts</h4> -->
            <!-- <h1 v-if="empty_data_error != ''">{{ empty_data_error }}</h1>
                        <table v-else class="table table-hover table-striped table-responsive" id="audios_table">
                            <thead>
                                <tr class="frown" style="color:#303030 !important">
                                    <th scope="col" style="width: 2%">#</th>
                                    <th scope="col" style="width: 10%">Name</th>
                                    <th scope="col" style="width: 10%">Platform</th>
                                    <th scope="col" style="width: 5%">Order Id</th>
                                    <th scope="col" style="width: 5%">Status</th>
                                    <th scope="col" style="width: 5%">Total</th>
                                    <th scope="col" style="width: 5%">Date</th>
                                    <th scope="col" style="width: 5%">Action</th>
                                </tr>
                            </thead>
                        </table> -->

        </div>
        <!-- <vue-snotify></vue-snotify> -->
    </section>

</template>

<script>

import api from "../../config/api.js";
import $ from "jquery";
// import AudioEditModal from "../modals/audio-modals/AudioEditModal.vue";
export default {
    data() {
        return {
            empty_data_error: "",
            order: [],
            history: [],
            history_total : 0,
            latest_orders: [],
            total_of_history : 0,
            total_of_other_order : 0,
        }
    },
    mounted() {
        this.getOrders();
    },

    methods: {

        goBack() {
            let prev_url = "/all-orders";
            this.$router.push({ path: prev_url });
        },

        async getOrders() {
            try {
                let result = await api.get(`admin/order-details/${this.$route.params.id}`);
                if (result.status == 200) {
                    this.order = result?.data?.order;
                    this.history = result?.data?.history;

                    const prices = this.history.map((product) => product.price_in_purchased_currency)
                    console.log(prices , ' prices ');
                    this.history_total = prices.reduce((acc, curr) => Number(acc) + Number(curr))
                    this.latest_orders = result?.data?.latest_orders;

                    let records1 = this.history.filter(val=>val.price_in_purchased_currency != null);
                    let records2 = this.latest_orders.filter(val=>val.price_in_purchased_currency != null);

                   
                    // arr.reduce((total, obj) => obj.credit + total,0)

                    if(records1){

                        let records1_total = records1.reduce((total,obj) => Number(obj?.price_in_purchased_currency) + Number(total),0)
    
                    console.log('check totals ');
                    console.log(records1_total);

                        // this.total_of_history = (records1[0]?.pay) ? records1[0]?.pay : 0;
                        this.total_of_history = records1_total.toFixed(2);
                    }
                    if(records2){
                        let records2_total = records2.reduce((total,obj) => Number(obj?.price_in_purchased_currency) + Number(total),0)
                        console.log(records2_total);
                        this.total_of_other_order = records2_total.toFixed(2);
                        // this.total_of_other_order = (records2[0]?.pay) ? records2[0]?.pay : 0;
                    }

                    
                    console.log(this.order)
                    console.log(this.history)
                    console.log(this.latest_orders)
                    // setTimeout(()=>{
                    //     $("#audios_table").DataTable();
                    // },2000);
                }
            } catch (error) {
                console.log(error);
            }
        },

    }

}

</script>



<style>

table{
    display: inline-table !important;
}

table td {
    white-space: normal !important;
}
</style>